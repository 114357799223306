/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource theme-ui */
import {
  captureConsoleIntegration,
  httpClientIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import Script from 'next/script';
import { ToastContainer } from 'react-toastify';
import { Container, ThemeProvider } from 'theme-ui';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { GlobalContextProvider } from '../components/GlobalContextProvider/GlobalContextProvider';
import Reset from '../lib/reset';
import theme from '../lib/theme';
import 'react-toastify/dist/ReactToastify.css';

if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    debug: false,
    integrations: [
      Sentry.feedbackIntegration({
        colorScheme: 'light',
        autoInject: false,
      }),
      Sentry.browserTracingIntegration(),
      captureConsoleIntegration(),
      httpClientIntegration(),
    ],

    release: 'default',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'local',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    sendDefaultPii: true,

    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error && error.message) {
        if (error.message.match(/Load failed/i)) {
          event.fingerprint = ['Load failed'];
        }

        if (
          error.message.match(
            /WagtailApiResponseError: Bad Gateway\. Url: \/accounts\/autologin\//i,
          )
        ) {
          event.fingerprint = [
            'WagtailApiResponseError: Bad Gateway. Url: /accounts/autologin/',
          ];
        }

        if (
          error.message.match(
            /WagtailApiResponseError: Bad Gateway\. Url: \/wt\/api\/nextjs\/v1\/profile\//i,
          )
        ) {
          event.fingerprint = [
            'WagtailApiResponseError: Bad Gateway. Url: /wt/api/nextjs/v1/profile/',
          ];
        }

        // Completely ignore this error - it's normal user navigation behavior
        if (error.message.match(/Loading initial props cancelled/i)) {
          return null;
        }
      }

      return event;
    },
  });
}

const GTMOnReadyHandler = () => {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    userId: 'unknown',
    userIsAuthenticated: 'unknown',
  });

  const userFromLS = window.localStorage.getItem('user');
  if (userFromLS) {
    try {
      const userJSON = JSON.parse(userFromLS);
      if (userJSON) {
        window.dataLayer.push({
          event: 'read-from-localstorage',
          userIsAuthenticated: true,
          userId: userJSON.userId,
          email: userJSON.email,
          subscriptionType: userJSON.userLevel,
          subscriptionId: userJSON?.userMetadata?.subscriptionId,
          ...userJSON,
        });
      }
    } catch (e) {
      console.error('error name', e.name, '\n', 'error.res', e.response, e);
    }
  }
};

const MyApp = ({ Component, pageProps }) => (
  <ErrorBoundary>
    <GlobalContextProvider>
      <ThemeProvider theme={theme}>
        <Script id='GTMOnReadyScript' onReady={GTMOnReadyHandler}>
          {process.env.NEXT_PUBLIC_GTM_HEAD}
        </Script>
        <Reset />
        <Container>
          <Component {...pageProps} />
          <ToastContainer />
        </Container>
      </ThemeProvider>
    </GlobalContextProvider>
  </ErrorBoundary>
);

export default MyApp;
